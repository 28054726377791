import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import http from './utils/http'
import VuexPersist from 'vuex-persist'

const vuexPersist = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage
})

Vue.config.productionTip = false

import "../public/css/materialdesignicons.min.css";
import 'swiper/swiper-bundle.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-toast-notification/dist/theme-sugar.css';

import { BootstrapVue } from 'bootstrap-vue'
let VueScrollTo = require('vue-scrollto');
import Scrollspy from 'vue2-scrollspy';
import VueYoutube from 'vue-youtube'
import vueVimeoPlayer from 'vue-vimeo-player'
import VueMasonry from 'vue-masonry-css'
import VueToast from 'vue-toast-notification';


Vue.use(VueScrollTo, {
  duration: 3000,
  easing: "ease"
})

Vue.use(Vuex)
Vue.use(BootstrapVue)
Vue.use(Scrollspy);
Vue.use(VueYoutube)
Vue.use(vueVimeoPlayer)
Vue.use(VueMasonry)
Vue.use(VueToast);

Vue.prototype.$http = http

const store = new Vuex.Store({
  plugins: [vuexPersist.plugin],
  state: {
    style: {
      theme: 'dark',
    },
    userData: {
      email: '',
    }
  },
  mutations: {
    setDarkTheme (state) {
      state.style.theme = 'dark';
    },
    setLightTheme (state) {
      state.style.theme = 'light';
    },
    setUserEmail (state, email) {
      if (email) {
        state.userData.email = email;
      }
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
