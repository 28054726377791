import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/homepage'),
    meta: {
      titleName: 'Home'
    }
  },
  {
    path: '/faq',
    name: 'faqs',
    component: () => import('../views/faqs'),
    meta: {
      titleName: 'FAQ'
    }
  },
  {
    path: '/buy',
    name: 'buy',
    component: () => import('../views/buy'),
    meta: {
      titleName: 'Checkout'
    }
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('../views/success'),
    meta: {
      titleName: 'Thank you'
    }
  },
  {
    path: '/success-payment',
    name: 'success-payment',
    component: () => import('../views/success-payment'),
    meta: {
      titleName: 'Thank you'
    }
  },
  // {
  //   path: '/contact',
  //   name: 'contact-us',
  //   component: () => import('../views/contact-us')
  // },
  {
    path: '*',
    component: () => import('../views/page-error'),
    meta: {
      titleName: '404'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
